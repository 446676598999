import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'places.edit',
  mixins: [validationMixin],
  data() {
    return {
      translations: [],
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    translations: {
      $each: {
        title: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        description: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(60)
        }
      }
    }
  },
  created() {
    let placeId = parseInt(this.$route.params.placeId);
    if (!this.place || this.place.id !== placeId) {
      this.fetchPlace(placeId).then(()=>{
        this._applyData();
      });
    } else {
      this._applyData();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      place: 'places/place',
      isPlaceLoading: 'places/isPlaceLoading',
      isPlaceUpdating: 'places/isPlaceUpdating',
    }),
    ...mapGetters([
      'languages',
    ]),
  },
  methods: {
    ...mapActions({
      fetchPlace: 'places/PLACES_REQUEST_DATA_GET',
      editPlace: 'places/PLACES_REQUEST_UPDATE',
    }),
    ...mapMutations({}),
    _applyData() {
      this.translations = this.languages.map(langItem => {
        let lang = this.place.translations.data.filter(translationItem => {
          return translationItem.locale === langItem.translationKey;
        })[0];

        let translationItem = {
          locale: langItem.translationKey,
          title: '',
          description: '',
        }

        if (lang) {
          translationItem = Object.assign(translationItem, {
            title: lang.title,
            description: lang.description,
          })
        }
        return translationItem
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    translationsDescriptionErrors(index) {
      let error = [];
      if (!this.$v.translations.$each[index].description.$dirty) {
        return error;
      }
      if (!this.$v.translations.$each[index].description.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.translations.$each[index].description.minLength) {
        error.push(this.$t('validationMax',{count: 2}));
      }
      if (!this.$v.translations.$each[index].description.maxLength) {
        error.push(this.$t('validationMax',{count: 60}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {};
        this.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title,
            description: item.description,
          }
        });
        this.editPlace({
            placeId: this.place.id,
            payload: payload
          }).then(() => {
            this.$toasted.success(this.$t('dataUpdated'));
            this.$router.push({name: 'places.list'}).catch(() => {console.log();});
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
